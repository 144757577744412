<template>
  <div>
       <!-- 创建 -->
        <div style="width:500px;margin:0 auto;background:#fff;">
            <div style="text-align:left;">

               <el-row>
                    <p style="width:100%;font-size:16px;line-height:40px;height:40px;">帐号：</p>
                    <el-input
                    placeholder="请输入内容"
                    v-model="saveData.account"
                    clearable>
                    </el-input>
               </el-row>
                <el-row>
                    <p style="width:100%;font-size:16px;line-height:40px;height:40px;">姓名：</p>
                    <el-input
                    placeholder="请输入内容"
                    v-model="saveData.username"
                    clearable>
                    </el-input>
               </el-row>
                <el-row>
                    <p style="width:100%;font-size:16px;line-height:40px;height:40px;">工号：</p>
                    <el-input
                    placeholder="请输入内容"
                    v-model="saveData.job_number"
                    clearable>
                    </el-input>
               </el-row>
                <el-row>
                    <p style="width:100%;font-size:16px;line-height:40px;height:40px;">手机号：</p>
                    <el-input
                    placeholder="请输入内容"
                    v-model="saveData.tel"
                    clearable>
                    </el-input>
               </el-row>
                <el-row>
                    <p style="width:100%;font-size:16px;line-height:40px;height:40px;">密码：</p>
                    <el-input
                    placeholder="请输入内容"
                    show-password
                    v-model="password"
                    clearable>
                    </el-input>
               </el-row>
                <el-row>
                    <p style="width:100%;font-size:16px;line-height:40px;height:40px;">头像：</p>
                    <!-- <el-upload 
                        :action="fileUrl"
                        :on-preview="pickPicture"
                        :on-error="handleError"
                        :file-list="uploadImages"
                        :headers="authorizationBearer"
                        list-type="text"
                        >
                        <el-button size="small" type="primary" style="width:108px;margin-left:15px;">点击上传</el-button>
                    </el-upload> -->
                    <el-upload
                    class="avatar-uploader"
                    :action="fileUrl"
                    :show-file-list="false"
                    :on-success="pickPicture"
                    :before-upload="beforeUpload"
                    :headers="authorizationBearer"
                    >
                    <img v-if="imageUrl" :src="imageUrl" class="avatar" v-loading="uploadLoading">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>

               </el-row>

                <el-row>
                    <p style="width:100%;font-size:16px;line-height:40px;height:40px;margin-top:20px;">员工状态</p>
                    <div>
                        <el-radio-group v-model="saveData.enable_status">
                        <el-radio-button label="1">启用</el-radio-button>
                        <el-radio-button label="2">禁用</el-radio-button>
                        </el-radio-group>
                    </div>
                </el-row>
            </div>

            <div slot="footer" class="dialog-footer" style="text-align:center;height:60px;line-height:60px;margin-top:60px;">
                <el-button type="primary" v-if="!saveComplete" @click="save();">保 存</el-button>
                <el-button type="primary" v-if="saveComplete" @click="exit();">关 闭</el-button>
            </div>
        </div> 
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import tools from "../../../extends/tools";
import apis from "../../common/js/api2";

export default {
    props:{
        id:{
            type:Number,
            default:0
        }
    },
    destroyed(){
        
    },
    data(){
        return {
            fileUrl: tools.baseFileUpload(),
            baseUrl: tools.baseURL(),
            saveComplete:false,
            uploadLoading:false,
            imageUrl:'',
            password:'',
            authorizationBearer: { 'authorization-bearer': localStorage.getItem('authorization-bearer') },
            saveDataInit:{
                portrait:'',
                account:'',
                username:'',
                job_number:'',
                tel:'',
                password:'',
                enable_status:1
            },
            saveData:{

            },
            format:'YYYY-MM-DD HH:mm:ss'
        }
    },
    methods:{
        copy(code,item){

        },
        beforeUpload(){
            this.uploadLoading = true;
        },
        //插入图片
        pickPicture(file) {
            this.uploadLoading = false;
            this.saveData.portrait = file.font;
            this.imageUrl = this.baseUrl+file.font;
        },
        save(){
            this.saveData.password = tools.encrypt(this.password);
            //判断是修改还是新增
            if(this.id > 0){
                this.saveData.id = this.id;
                //保存  
                apis.staffSave(this.saveData).then((res)=>{
                    if(res.code != 200){
                        this.$message({
                            type:'error',
                            message:res.msg
                        });
                        return;
                    }
                    this.$message({
                        type:'success',
                        message:'保存成功'
                    });
                    //通知列表页更新数据
                    this.fleshList();
                }).catch((err)=>{
                    console.log(err);
                    this.$message('网络不通，请稍候重试-');
                });                
                
                return;
            }

            //新增
            apis.staffAdd(this.saveData).then((res)=>{
                if(res.code != 200){
                    this.$message({
                        type:'error',
                        message:res.msg
                    });
                    return;
                }
                //显示分享码
                this.saveComplete = true;
                this.$message({
                    type:'success',
                    message:'添加员工成功'
                });
                //通知列表页更新数据
                this.fleshList();
            }).catch((err)=>{
                console.log(err);
                this.$message('网络不通，请稍候重试-');
            });

        },
        exit(){
            this.$emit('shareExit')
        },
        fleshList(){
            this.$emit('fleshList')
        }
    },
    created(){
        // alert(this.id);
        //判断是修改还是新增
        if(this.id > 0){
            //初始化修改数据
            apis.staffDetail({id:this.id}).then((res)=>{
                res.data.password = ''; 
                this.saveData = res.data;
                this.saveData.id = this.id;
                this.imageUrl = this.baseUrl + res.data.portrait;
            }).catch((err)=>{
                console.log(err);
                this.$message('网络错误，获取数据失败');
            });
        }else{
            this.saveData = tools.copy(this.saveDataInit);
        }
    }
}
</script>

<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 78px;
    height: 78px;
    line-height: 78px;
    text-align: center;
  }
  .avatar {
    width: 78px;
    height: 78px;
    display: block;
  }
</style>