<template>
  <div>
       <!-- 创建 -->
        <div style="width:500px;margin:0 auto;background:#fff;">
            <div style="text-align:left;">
               <el-row style="margin:15px 0 15px 0;">
                    <p >授权店铺（授权用户拥有哪些店铺的浏览权限）：  </p>
                    <p style="margin:10px 0 10px 0;">
                        <el-select v-model="data.saveData.shop" multiple placeholder="请选择">
                            <el-option
                            :key="k" v-for="(i,k) in data.shopList"
                            :label="i.name"
                            :value="i.id">
                            </el-option>
                        </el-select>
                    </p>
               </el-row>
               
               <el-row style="margin:15px 0 15px 0;">
                    <p >授予权限（授权用户可以访问哪些菜单）：</p>
                    <p>
                        <el-tree
                        @check-change="checkChange"
                        ref="tree"
                        :data="data.allAuth"
                        :default-checked-keys="data.checkIdList"
                        show-checkbox
                        node-key="id"
                        :props="defaultProps">
                        </el-tree>
                    </p>
               </el-row>

            </div>
            <div slot="footer" class="dialog-footer" style="text-align:center;height:60px;line-height:60px;margin-top:60px;">
                <el-button type="primary"  @click="save();">保 存</el-button>
            </div>
        </div> 
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import tools from "../../../extends/tools";
import apis from "../../common/js/api2";

export default {
    props:{
        id:{
            type:Number,
            default:0
        }
    },
    destroyed(){
        this.data = tools.copy(this.initData);
        this.id = 0;
    },
    data(){
        return {
            // 树形菜单默认匹配属性
            defaultProps: {
                children: 'child',
                label: 'name'
            },
            data:{
                saveData:{
                    shop:[],
                    auth:[]
                },
                shopList:[],
                authList:[],
                allAuth:[],
                authIdList:[]
            },
            initData:null,
            checkIdList:[],
            checkShopList:[]
        }
    },
    computed:{
        _authList:function () {
            let arr = [];

            return this.data.allAuth;
        },
    },
    methods:{
        checkChange(obj){
            //父级菜单
            let res = this.$refs.tree.getCheckedNodes()
            this.data.authIdList = [];
            res.forEach((item) => {
                this.data.authIdList.push(item.id);
            });
        },
        copy(code,item){

        },
        beforeUpload(){
            this.uploadLoading = true;
        },
        //插入图片
        pickPicture(file) {
            this.uploadLoading = false;
            this.saveData.portrait = file.font;
            this.imageUrl = this.baseUrl+file.font;
        },
        save(){
            this.data.saveData.shop = JSON.stringify(this.data.saveData.shop);
            this.data.saveData.auth = JSON.stringify(this.data.authIdList);
            this.data.saveData.id = this.id;
            //新增
            apis.staffAuthSave(this.data.saveData).then((res)=>{
                tools.msg(res,this,1);
                //通知列表页更新数据
                this.fleshList();
            }).catch((err)=>{
                console.log(err);
                this.$message('网络不通，请稍候重试-');
            });

        },
        exit(){
            this.$emit('shareExit')
        },
        fleshList(){
            this.$emit('fleshList')
        }
    },
    created(){
        if(this.initData === null){
            this.initData = tools.copy(this.data);
        }
        // 所有的权限列表
        apis.allAuthList().then(res=>{
            if(tools.msg(res,this)){
                this.data.allAuth = res.data.auth;
                this.data.shopList = res.data.shop;
            }
        }).catch(err=>{
            tools.err(err,this);
        });

        //获取员工详情
        apis.staffDetail({id:this.id}).then(res=>{
            if(tools.msg(res,this)){
                this.data.saveData.shop = res.data.bind_info?JSON.parse(res.data.bind_info.shop_id):[];
                this.data.checkIdList = res.data.bind_info?JSON.parse(res.data.bind_info.auth_id):[];
            }
        }).catch(err=>{
            tools.err(err,this);
        });

        //获取员工拥有的权限    
        // apis.staffAuthList({
        //     id:this.id
        // }).then(res=>{
        //     if(tools.msg(res,this)){
        //         this.data.authList = res.data;    
        //     }
        // }).catch(err=>{
        //     tools.err(err,this);
        // });
        

    }
}
</script>

<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 78px;
    height: 78px;
    line-height: 78px;
    text-align: center;
  }
  .avatar {
    width: 78px;
    height: 78px;
    display: block;
  }
</style>